const Data = [
    {
        requiredHours: 100,
        gifURL: "https://i.giphy.com/media/5Zesu5VPNGJlm/source.gif",
        existsURL: false,
        message:"You can learn to code and get a tech job!",
        URL: "",
        message2: "",
        message3: ""
    },
    {
        requiredHours: 100,
        gifURL: "https://i.giphy.com/media/QbkL9WuorOlgI/giphy.webp",
        existsURL: false,
        message:"You can spend more quality time with your loved ones! ❤️",
        URL: "",
        message2: "",
        message3: ""
    },
    {
        requiredHours: 100,
        gifURL: "https://i.giphy.com/media/xThuWwRYOZdfcODqVy/giphy.webp",
        existsURL: false,
        message:"You have a lot more time for meditation and exercise. Stay healthy!",
        URL: "",
        message2: "",
        message3: ""
    },
    {
        requiredHours: 1100,
        gifURL: "https://media.tenor.com/images/24827d8ae580f99b6955930bf461cab2/tenor.gif",
        existsURL: true,
        URL: "https://www.clozemaster.com/blog/how-long-does-it-take-to-learn-a-language/",
        message:"You can master almost language you want!",
        message2: "",
        message3: ""
    },
    {
        requiredHours: 2200,
        gifURL: "https://media.tenor.com/images/24827d8ae580f99b6955930bf461cab2/tenor.gif",
        existsURL: true,
        URL: "https://www.clozemaster.com/blog/how-long-does-it-take-to-learn-a-language/",
        message:"GG! You can master any language you choose! 🔥",
        message2: "",
        message3: ""
    },
    {
        requiredHours: 3000,
        gifURL: "https://i.makeagif.com/media/7-12-2015/kYhjVR.gif",
        existsURL: false,
        message:"You can save enough time to learn a college-degree's worth online! 🎓",
        message2: "",
        message3: "",
        URL: ""
    },
    {
        requiredHours: 10000,
        gifURL: "https://i.giphy.com/media/1jl173guBKkbvC03rQ/giphy.webp",
        existsURL: false,
        message:"WOAH! You can become an utra-competitive expert in any field you want with time like this!",
        message2: "Put in the hours 💪",
        message3: "Maybe try advanced math!",
        URL: ""
    },
    {
        requiredHours: 10000,
        gifURL: "https://i.gifer.com/V4WN.gif",
        existsURL: false,
        message:"WOAH! You can become an utra-competitive expert in any field you want with time like this!",
        message2: "Put in the hours 💪",
        message3: "Hmmm, Computer Science looks interesting!",
        URL: ""
    },
    {
        requiredHours: 10000,
        gifURL: "https://booksrockmyworlddotcom.files.wordpress.com/2019/03/bookworm-gif.gif",
        existsURL: false,
        message:"WOAH! You can become an utra-competitive expert in any field you want with time like this!",
        message2: "Put in the hours 💪",
        message3: "Or, you know, you could just read a million pages of books! 😂",
        URL: ""
    }
];

export default Data;