import React from 'react';

import Emoji from './Emoji';

const NavBar = () => {

    return(
        <div className="NavBar">
            <h1><Emoji symbol="🚀" value="rocket"/>Own the <br/> &nbsp; &nbsp; 2020s</h1>
        </div>
    );


}

export default NavBar;